import React from 'react';

import styled from 'styled-components';

const Video = ({ id, shadowed, ...otherProps }) => (
	<VideoWrapper shadowed={shadowed} {...otherProps}>
		<iframe
			type="text/html"
			width="720"
			height="405"
			src={`https://www.youtube.com/embed/${id}?color=white&rel=0`}
			frameBorder="0"
			allowFullScreen
			title="Video"
		/>
	</VideoWrapper>
);

export default Video;

const VideoWrapper = styled.div`
	padding-top: ${100 / (16 / 9)}%;
	position: relative;
	${({ shadowed }) => shadowed && `box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);`}

	& iframe {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		margin: 0 auto;
		display: block;
	}
`;
