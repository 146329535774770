import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import Colors from '../constants/colors';

const StyledName = ({ givenName, familyName }) => (
	<Wrapper>
		<GivenName>
			<GivenNameText>{givenName}{' '}</GivenNameText>
		</GivenName>
		<FamilyName>
			<FamilyNameText>{familyName}</FamilyNameText>
		</FamilyName>
	</Wrapper>
);
StyledName.propTypes = {
	givenName: PropTypes.string.isRequired,
	familyName: PropTypes.string.isRequired,
};
export default StyledName;

const Wrapper = styled.span`
	padding-left: 1em;
	position: relative;
	display: inline-block;
	color: white;
	text-transform: uppercase;
	font-weight: bold;
	font-style: italic;
	line-height: 1;
	transform: rotate(-5deg);
	transform-origin: left bottom;
	font-family: "Barlow Condensed", sans-serif;
	white-space: nowrap;

	&::before, &::after {
		content: '';
		display: block;
		position: absolute;
		background-color: ${Colors.magenta};
		left: 0;
		bottom: 0;
		transform: skewX(-20deg);
		transform-origin: left bottom;
	}
	&::before {
		height: calc(1em + 0.2em);
		width: 1em;
	}
	&::after {
		width: 100%;
		height: 0.3em;
	}
`;

const GivenName = styled.span`
	position: relative;
	z-index: 1;
	bottom: calc(0.3em + 0.1em);
	left: 0.06em;
	display: inline-block;
	padding: 0 0.1em 0.15em 0.7em;
	vertical-align: bottom;

	&::before {
		content: '';
		background-color: ${Colors.turquoiseBlue};
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		transform: skewX(-20deg);
		transform-origin: left bottom;
	}
`;

const GivenNameText = styled.span`
	font-size: 180%;
	position: relative;
`;

const FamilyName = styled.span`
	display: inline-block;
	padding: 0.05em 0.4em 0.15em 0.6em;
	position: relative;
	vertical-align: bottom;
	z-index: 1;

	&::before {
		content: '';
		background-color: ${Colors.magenta};
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		height: 100%;
		transform: skewX(-20deg);
		transform-origin: left bottom;
	}
`;

const FamilyNameText = styled.span`
	position: relative;
`;
