import React from 'react';

import { graphql } from 'gatsby';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import Layout from '../components/layout';
import StyledName from '../components/styled-name';
import Video from '../components/video';
import WidthLimit from '../components/width-limit';

import displayUrl from '../util/display-url';

import Colors from '../constants/colors';
import Sizes, { min } from '../constants/sizes';

const SponsorsPage = ({
	data: {
		sponsors: { nodes: sponsors },
	},
}) => (
	<Layout>
		<Helmet>
			<title>Sponsors</title>
		</Helmet>
		<main>
			<Header className="grayDotted">
				<WidthLimit>
					<h2>
						<StyledName givenName="VNTA" familyName="sponsors" />
					</h2>
					<p>
						Thank you to VNTA’s sponsors who each made incredible contributions to the show.
						One of the many reasons you should trust them with your business?
						They made the sensible choice to be part of the show, demonstrating that they have exceptional judgement!
					</p>
				</WidthLimit>
			</Header>
			<ContentSection>
				<WidthLimit>
					{sponsors.map(s => (
						<Card key={s.frontmatter.name}>
							<LogoAndVideo>
								<header>
									<h3>
										<img src={s.frontmatter.logo.publicURL} alt={s.frontmatter.name} />
									</h3>
								</header>
								{s.frontmatter.youtubeId && (
									<VideoContainer>
										<Video id={s.frontmatter.youtubeId} />
									</VideoContainer>
								)}
							</LogoAndVideo>
							<div className="content" dangerouslySetInnerHTML={{ __html: s.html }} />
							<footer>
								<p>
									<a href={s.frontmatter.url} target="_blank" rel="noopener">{displayUrl(s.frontmatter.url)}</a>{/* eslint-disable-line react/jsx-no-target-blank */}
									<br />
									<a href={`tel:${s.frontmatter.phone}`}>{s.frontmatter.phone}</a>
								</p>
							</footer>
						</Card>
					))}
				</WidthLimit>
			</ContentSection>
		</main>
	</Layout>
);

export default SponsorsPage;

const Header = styled.header`
	padding: 4rem 0;

	& h2 {
		font-size: 1.2rem;

		${min.tablet`
			font-size: 2rem;
		`}
	}

	${min.desktop`
		p {
			max-width: 30em;
		}
	`}
`;

const ContentSection = styled.article`
	background-color: white;
	color: #333;
	padding: 2rem 0;

	& a {
		color: ${Colors.darkMagenta};
	}
`;

const VideoContainer = styled.div`
	width: 90%;
	margin: 2rem auto 0;

	${min.tablet`
		width: 40%;
		margin: 0;
	`}
`;

const LogoAndVideo = styled.div`
	display: flex;
	flex-direction: column;

	${min.tablet`
		flex-direction: row;
		grid-column: 1 / 3;
	`}
`;

const Card = styled.div`
	display: grid;
	grid-template-columns: auto;
	grid-gap: 0 2rem;
	margin: 6rem 0;

	${min.tablet`
		grid-template-columns: auto auto;
	`}

	& header {
		flex: auto;

		& h3 {
			margin: 0;
		}

		& img {
			display: block;
			max-width: 500px;
			margin: 0 auto;
			@supports (object-fit: contain) {
				object-fit: contain;
				object-position: center;
				width: 80%;
				max-height: 12rem;
			}
		}
	}

	& footer {
		text-align: center;

		& p {
			@media (max-width: ${Sizes.tablet - 1}px) {
				margin: 0;
			}
		}

		${min.tablet`
			text-align: right;
		`}
	}
`;

export const query = graphql`
	query {
		sponsors: allMarkdownRemark(
			filter: { fileAbsolutePath: { glob: "**/src/sponsors/*.md" } }
			sort: { fields: [frontmatter___order, frontmatter___name] }
		) {
			nodes {
				frontmatter {
					name
					url
					phone
					logo {
						publicURL
					}
					youtubeId
				}
				html
			}
		}
	}
`;
